
import { DisplayField, Theme } from "@/models/template";
import { defineComponent, PropType } from "vue";

const black = "#2c3e50";

export default defineComponent({
  name: "DisplayFieldComponent",
  components: {},

  props: {
    displayField: {
      required: true,
      type: Object as PropType<DisplayField>,
    },
    theme: {
      type: Object as PropType<Theme>,
    },
  },

  computed: {
    setLabelColor() {
      return this.theme?.displayFieldLabelTextColor
        ? this.theme?.displayFieldLabelTextColor
        : black;
    },
    setValueColor() {
      return this.theme?.displayFieldValueTextColor
        ? this.theme?.displayFieldValueTextColor
        : black;
    },
  },
});
