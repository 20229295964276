
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loading",

  props: {
    largeSpinner: {
      required: true,
      type: Boolean,
    },
  },
});
