
import { Button } from "@/models/template";
import Loading from "@/components/Loading.vue";
import { defineComponent, PropType } from "vue";
import tinycolor from "tinycolor2";

const black = "black";
const white = "white";

const __default__ = defineComponent({
  name: "ButtonComponent",
  components: { Loading },

  data() {
    return {
      isLoading: false,
    };
  },

  props: {
    button: {
      required: true,
      type: Object as PropType<Button>,
    },
    theme: {
      required: true,
      type: String,
    },
  },

  computed: {
    textColor() {
      const currentColor = tinycolor(this.theme);
      if (currentColor.isLight()) {
        return black;
      }
      return white;
    },

    onHover() {
      const currentColor = tinycolor(this.theme);
      if (currentColor.isLight()) {
        return currentColor.darken(10);
      }
      return currentColor.lighten(10);
    },
  },

  methods: {
    buttonClicked() {
      this.isLoading = true;
      this.$emit("buttonClicked", this.button.action, this.button.actionNext);
    },
  },

  emits: ["buttonClicked"],
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0a6bb356": (_ctx.theme),
  "61aeb8c2": (_ctx.textColor),
  "71005c29": (_ctx.onHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__