
import { Button, ButtonAction, Theme } from "@/models/template";
import { defineComponent, PropType } from "vue";
import ButtonComponent from "./Button.vue";

export default defineComponent({
  name: "Buttons",
  components: { ButtonComponent },

  data() {
    return {
      isDisabled: false,
    };
  },

  setup() {
    const defaultColor = "#000000";
    return { defaultColor };
  },
  props: {
    buttons: {
      required: true,
      type: Array as PropType<Button[]>,
    },
    theme: {
      type: Object as PropType<Theme>,
    },
  },

  methods: {
    buttonClicked(action: string, actionNext?: string) {
      this.isDisabled = true;
      this.$emit("buttonClicked", this.isDisabled, action, actionNext);
    },

    selectButtonTheme(action: ButtonAction): string {
      if (!this.theme) {
        return this.defaultColor;
      }
      switch (action) {
        case ButtonAction.CONTINUE: {
          if (this.theme.buttonPrimaryBackgroundColor) {
            return this.theme.buttonPrimaryBackgroundColor;
          }
          break;
        }
        case ButtonAction.CANCEL: {
          if (this.theme.buttonSecondaryBackgroundColor) {
            return this.theme.buttonSecondaryBackgroundColor;
          }
          break;
        }
        case ButtonAction.CANCEL_AND: {
          if (this.theme.buttonTertiaryBackgroundColor) {
            return this.theme.buttonTertiaryBackgroundColor;
          }
          break;
        }
        default: {
          return this.defaultColor;
        }
      }
      return this.defaultColor;
    },
  },

  emits: ["buttonClicked"],
});
