<template>
  <h2>Error Occured</h2>
  <h4>{{ error }}</h4>
</template>

<script>
export default {
  props: {
    error: {
      required: false,
    },
  },
};
</script>

<style></style>
