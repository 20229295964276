import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

export interface State {
  template?: unknown;
}
// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {},
  mutations: {},
  getters: {
    template: (state) => state.template,
  },
  actions: {},
  modules: {},
});
