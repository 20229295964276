
import { defineComponent, PropType } from "vue";
import DisplayFieldComponent from "./DisplayField.vue";
import { DisplayField, Theme } from "@/models/template";

export default defineComponent({
  name: "DisplayFields",
  components: { DisplayFieldComponent },

  props: {
    displayFields: {
      required: true,
      type: Array as PropType<DisplayField[]>,
    },
    theme: {
      type: Object as PropType<Theme>,
    },
  },
});
