
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SelectInput",
  components: {},

  data() {
    return {};
  },

  setup(props, { emit }) {
    console.log(props);
    const selectedValue = ref(props.defaultValue);
    emit("onUpdate", props.inputID, selectedValue);

    return { selectedValue };
  },

  props: {
    values: {
      required: true,
      type: Array,
    },
    defaultValue: {
      required: false,
      type: String || Number,
    },
    required: {
      required: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    inputID: {
      required: true,
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
  },

  methods: {
    update(event: Event) {
      console.log(event);
      this.$emit("onUpdate", this.inputID, this.selectedValue);
    },
  },
  emits: ["onUpdate"],
});
