import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.buttonClicked && _ctx.buttonClicked(...args))),
    class: "btn btn-lg shadow customButtonTheme"
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Loading, {
          key: 0,
          "large-spinner": false
        }))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.button.label), 1)
  ]))
}