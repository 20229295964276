
import { defineComponent, PropType } from "vue";
import NumberInput from "./NumberInput.vue";
import TextInput from "./TextInput.vue";
import SelectInput from "./SelectInput.vue";
import OtpInput from "./OtpInput.vue";
import { InputField } from "@/models/template";

export default defineComponent({
  name: "InputsComponent",
  components: { NumberInput, TextInput, SelectInput, OtpInput },

  data() {
    return {
      defaultInputLength: 4,
    };
  },

  setup() {
    const inputAnswers = new Map();
    return { inputAnswers };
  },

  props: {
    inputs: {
      required: true,
      type: Array as PropType<InputField[]>,
    },
    isDisabled: {
      type: Boolean,
    },
  },

  methods: {
    inputLength(inputMaxLength?: number, inputMinLength?: number): number {
      if (inputMaxLength) {
        return inputMaxLength;
      } else if (inputMinLength) {
        return inputMinLength;
      }
      return this.defaultInputLength;
    },

    onInputChange(id: string, value: string) {
      this.inputAnswers.set(id, value);
      this.$emit("inputUpdated", this.inputAnswers);
    },
  },
  emits: ["inputUpdated"],
});
