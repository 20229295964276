import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56f41bf3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logos" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FootLogoComponent = _resolveComponent("FootLogoComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logos, (logo) => {
      return (_openBlock(), _createElementBlock("div", { key: logo }, [
        _createVNode(_component_FootLogoComponent, { logo: logo }, null, 8, ["logo"])
      ]))
    }), 128))
  ]))
}