import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_otp_input = _resolveComponent("v-otp-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.inputID,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createVNode(_component_v_otp_input, {
      class: "otp-container",
      ref: "otpInput",
      value: _ctx.bindModal,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bindModal) = $event)),
      "input-classes": "otp-input",
      separator: "-",
      "num-inputs": _ctx.length,
      "should-auto-focus": true,
      "input-type": "number",
      required: _ctx.required,
      "is-disabled": _ctx.isDisabled,
      onOnChange: _ctx.update
    }, null, 8, ["value", "num-inputs", "required", "is-disabled", "onOnChange"])
  ]))
}