
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "FootLogoComponent",
  components: {},

  props: {
    logo: {
      required: true,
      type: Object as PropType<string>,
    },
  },
});
