import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayFieldComponent = _resolveComponent("DisplayFieldComponent")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayFields, (displayField) => {
    return (_openBlock(), _createElementBlock("div", {
      key: displayField.label
    }, [
      _createVNode(_component_DisplayFieldComponent, {
        displayField: displayField,
        theme: _ctx.theme
      }, null, 8, ["displayField", "theme"])
    ]))
  }), 128))
}