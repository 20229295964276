export interface Theme {
  pageBackgroundColor?: string;
  pageAlign?: PageAlign;
  titleTextColor?: string;
  overviewTextColor?: string;
  displayFieldLabelTextColor?: string;
  displayFieldValueTextColor?: string;
  inputFieldLabelTextColor?: string;
  inputFieldValueTextColor?: string;
  inputFieldValueBackgroundColor?: string;
  inputFieldInvalidValueTextColor?: string;
  inputFieldInvalidValueBackgroundColor?: string;
  conclusionTextColor?: string;
  buttonPrimaryBackgroundColor?: string;
  buttonPrimaryBorderColor?: string;
  buttonPrimaryBorderRoundedCorners?: boolean;
  buttonPrimaryTextColor?: string;
  buttonSecondaryBackgroundColor?: string;
  buttonSecondaryBorderColor?: string;
  buttonSecondaryBorderRoundedCorners?: boolean;
  buttonSecondaryTextColor?: string;
  buttonTertiaryBackgroundColor?: string;
  buttonTertiaryBorderColor?: string;
  buttonTertiaryBorderRoundedCorners?: boolean;
  buttonTertiaryTextColor?: string;
  linkTextColor?: string;
  linkUnderline?: boolean;
  errorMessageTextColor?: string;
}

export enum PageAlign {
  CENTER = "CENTER",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export interface UIPage {
  ordinal?: number;
  contentSubject?: string;
  contentFormat?: ContentFormat;
  contentJSON?: UIContentJSON;
  content?: string;
}
export enum ContentFormat {
  PLAIN_TEXT = "PLAIN_TEXT",
  COMMONMARK = "COMMONMARK",
  HTML = "HTML",
  TWIML = "TWIML",
  JSON = "JSON",
}

export interface UIContentJSON {
  theme?: Theme;
  headerLogoURIs?: string[];
  title: string;
  imageURI?: string;
  overview: string;
  displayFields?: DisplayField[];
  inputFields?: InputField[];
  inputErrorMessage?: string;
  conclusion?: string;
  buttons?: Button[];
  footerLogoURIs?: string[];
}

export interface InputField {
  inputID: string;
  label: string;
  type: InputFieldType;
  value?: string | number;
  values?: string | number[];
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  minToSelect?: number;
  maxToSelect?: number;
  pattern?: string;
  masked?: boolean;
  required: boolean;
}

enum InputFieldType {
  TEXT = "TEXT",
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  OTP = "OTP",
  NUMERIC = "NUMERIC",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
}

export interface Button {
  action: ButtonAction;
  actionNext?: ButtonNextAction;
  label: string;
}

export enum ButtonAction {
  CONTINUE = "CONTINUE",
  CANCEL = "CANCEL",
  RESET = "RESET",
  CANCEL_AND = "CANCEL_AND",
}

enum ButtonNextAction {
  RESEND_OOB = "RESEND_OOB",
  TRY_ANOTHER_MECHANISM = "TRY_ANOTHER_MECHANISM",
  PLEASE_EXPLAIN = "PLEASE_EXPLAIN",
  CONTACT_ACCT_PROVIDER = "CONTACT_ACCT_PROVIDER",
}

export interface InputAnswer {
  inputID: string;
  value: string | number;
}

export interface DisplayField {
  label: string;
  value: string;
}
