
import { defineComponent, ref } from "vue";
import VOtpInput from "vue3-otp-input";

export default defineComponent({
  name: "OtpInput",
  components: { VOtpInput },

  setup() {
    const bindModal = ref("");

    return { bindModal };
  },

  emits: ["onUpdate"],

  props: {
    length: {
      required: true,
      type: Number,
    },
    required: {
      required: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    inputID: {
      required: true,
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  methods: {
    update(value: string) {
      console.log(value);
      this.$emit("onUpdate", this.inputID, value);
    },
  },
});
