
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImageComponent",
  components: {},

  props: {
    imageURI: {
      required: true,
      type: String,
    },
  },
});
