
import { InputAnswer, UIContentJSON } from "@/models/template";
import { defineComponent, PropType, ref } from "vue";
import Buttons from "./Buttons.vue";
import DisplayFields from "./DisplayFields.vue";
import Logos from "./Logos.vue";
import FooterLogos from "./FooterLogos.vue";
import Inputs from "./Inputs.vue";
import Image from "./Image.vue";
import { ButtonAction } from "@entersekt/customerauthn-sdk-web/dist/uiContent/Button";
import { ActionNext } from "@entersekt/customerauthn-sdk-web/dist/shared/IResult";

const black = "#2c3e50";
const boldRegex = /\*\*(.*?)\*\*/gs;
const italicRegex = /\*(.*?)\*/gs;

export default defineComponent({
  name: "DisplayTemplate",
  emits: ["buttonClicked"],
  components: { Buttons, DisplayFields, Logos, Inputs, Image, FooterLogos },

  props: {
    template: {
      required: true,
      type: Object as PropType<UIContentJSON>,
    },
  },

  data() {
    return {
      isDisabled: false,
    };
  },

  setup() {
    const inputData = ref(new Map<string, number | string>());

    return { inputData };
  },

  computed: {
    setTitleColor() {
      if (this.template.theme?.titleTextColor) {
        return this.template.theme?.titleTextColor;
      }
      return black;
    },

    setOverviewColor() {
      return this.template.theme?.overviewTextColor
        ? this.template.theme.overviewTextColor
        : black;
    },
    setConclusionColor() {
      return this.template.theme?.conclusionTextColor
        ? this.template.theme?.conclusionTextColor
        : black;
    },
  },

  methods: {
    inputsUpdated(inputs: Map<string, string | number>) {
      this.inputData = inputs;
    },

    // uses regex to find and replace text wrapped with '**' to first make text bold,
    // then finds text wrapped with '*' to italicizes the text.
    emphasisText(text: string | undefined) {
      if (text) {
        const boldText = text.replace(boldRegex, "<strong>$1</strong>");
        return boldText.replace(italicRegex, "<em>$1</em>");
      }
      return text;
    },

    buttonClicked(
      isDisabled: boolean,
      action: ButtonAction,
      actionNext?: ActionNext
    ) {
      this.isDisabled = isDisabled;
      const form = document.getElementById("inputs-form") as HTMLFormElement;
      if (form && !form.checkValidity() && action === "CONTINUE") {
        form.classList.add("was-validated");
      } else {
        const inputsArray: InputAnswer[] = [];
        this.inputData.forEach((value, key) => {
          inputsArray.push({ inputID: key, value });
        });
        this.$emit("buttonClicked", action, inputsArray, actionNext);
      }
    },
  },
});
