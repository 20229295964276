import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1793c564"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["required", "maxlength", "minlength", "id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.inputID,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
      autocomplete: "one-time-code",
      required: _ctx.required,
      type: "text",
      class: "form-control",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
      maxlength: _ctx.maxLength,
      minlength: _ctx.minLength,
      id: _ctx.inputID,
      disabled: _ctx.isDisabled
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.value]
    ])
  ]))
}