import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-476f9622"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["required", "id", "name", "disabled"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.inputID,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _withDirectives(_createElementVNode("select", {
      class: "form-select",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
      required: _ctx.required,
      id: _ctx.inputID,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedValue) = $event)),
      name: _ctx.inputID,
      disabled: _ctx.isDisabled
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (value) => {
        return (_openBlock(), _createElementBlock("option", {
          key: value,
          value: value
        }, _toDisplayString(value), 9, _hoisted_3))
      }), 128))
    ], 40, _hoisted_2), [
      [_vModelSelect, _ctx.selectedValue]
    ])
  ]))
}