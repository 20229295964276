
import { defineComponent } from "vue";

const __default__ = defineComponent({
  data() {
    return {
      backgroundColor: "#ffffff",
    };
  },
  methods: {
    setBackgroundColor(background: string) {
      this.backgroundColor = background;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "277c3e94": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__