import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logos = _resolveComponent("Logos")!
  const _component_Image = _resolveComponent("Image")!
  const _component_DisplayFields = _resolveComponent("DisplayFields")!
  const _component_Inputs = _resolveComponent("Inputs")!
  const _component_Buttons = _resolveComponent("Buttons")!
  const _component_FooterLogos = _resolveComponent("FooterLogos")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.template.headerLogoURIs)
      ? (_openBlock(), _createBlock(_component_Logos, {
          key: 0,
          logos: _ctx.template.headerLogoURIs
        }, null, 8, ["logos"]))
      : _createCommentVNode("", true),
    _createElementVNode("h2", {
      class: "textFormat",
      style: _normalizeStyle({ color: _ctx.setTitleColor }),
      innerHTML: _ctx.emphasisText(_ctx.template.title)
    }, null, 12, _hoisted_2),
    (_ctx.template.imageURI)
      ? (_openBlock(), _createBlock(_component_Image, {
          key: 1,
          imageURI: _ctx.template.imageURI
        }, null, 8, ["imageURI"]))
      : _createCommentVNode("", true),
    _createElementVNode("h4", {
      class: "textFormat",
      style: _normalizeStyle({ color: _ctx.setOverviewColor }),
      innerHTML: _ctx.emphasisText(_ctx.template.overview)
    }, null, 12, _hoisted_3),
    (_ctx.template.displayFields)
      ? (_openBlock(), _createBlock(_component_DisplayFields, {
          key: 2,
          displayFields: _ctx.template.displayFields,
          theme: _ctx.template.theme
        }, null, 8, ["displayFields", "theme"]))
      : _createCommentVNode("", true),
    (_ctx.template.inputFields)
      ? (_openBlock(), _createBlock(_component_Inputs, {
          key: 3,
          onInputUpdated: _ctx.inputsUpdated,
          inputs: _ctx.template.inputFields,
          answers: _ctx.inputData,
          "is-Disabled": _ctx.isDisabled
        }, null, 8, ["onInputUpdated", "inputs", "answers", "is-Disabled"]))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: "textFormat",
      style: _normalizeStyle({ color: _ctx.setConclusionColor }),
      innerHTML: _ctx.emphasisText(_ctx.template.conclusion)
    }, null, 12, _hoisted_4),
    (_ctx.template.buttons)
      ? (_openBlock(), _createBlock(_component_Buttons, {
          key: 4,
          onButtonClicked: _ctx.buttonClicked,
          buttons: _ctx.template.buttons,
          theme: _ctx.template.theme
        }, null, 8, ["onButtonClicked", "buttons", "theme"]))
      : _createCommentVNode("", true),
    (_ctx.template.footerLogoURIs)
      ? (_openBlock(), _createBlock(_component_FooterLogos, {
          key: 5,
          logos: _ctx.template.footerLogoURIs
        }, null, 8, ["logos"]))
      : _createCommentVNode("", true)
  ]))
}