
import { defineComponent } from "vue";

export default defineComponent({
  name: "NumberInputComponent",
  components: {},

  data() {
    return {
      value: "",
    };
  },

  setup(props) {
    console.log(props);
  },

  props: {
    required: {
      required: false,
      type: Boolean,
    },
    max: {
      required: false,
      type: Number,
    },
    min: {
      required: false,
      type: Number,
    },
    label: {
      required: true,
      type: String,
    },
    inputID: {
      required: true,
      type: String,
    },
    isDisabled: {
      type: Boolean,
    },
  },

  methods: {
    update(event: Event) {
      console.log(event);
      this.$emit("onUpdate", this.inputID, this.value);
    },
  },
  emits: ["onUpdate"],
});
