
import { defineComponent, ref } from "vue";
import DisplayTemplate from "../components/DisplayTemplate.vue";
import Loading from "../components/Loading.vue";
import Error from "../components/Error.vue";
import { useRoute } from "vue-router";
import SDK from "@entersekt/customerauthn-sdk-web";
import {
  ButtonAction,
  InputAnswer,
  UIContentJSON,
  UIPage,
} from "@/models/template";
import { ISetAuthenticationFactor } from "@entersekt/customerauthn-sdk-web/dist/setAuthenticationFactor/ISetAuthenticationFactor";
import { IChallenge } from "@entersekt/customerauthn-sdk-web/dist/challengeCustomer/IChallenge";
import { INotify } from "@entersekt/customerauthn-sdk-web/dist/notify/INotify";
import { ICustomerAuthnConfig } from "@entersekt/customerauthn-sdk-web/dist/ICustomerAuthnConfig";
import { IIdentifyCustomer } from "@entersekt/customerauthn-sdk-web/dist/identifyCustomer/IIdentifyCustomer";
import { ActionNext } from "@entersekt/customerauthn-sdk-web/dist/shared/IResult";

export default defineComponent({
  name: "Home",
  components: { DisplayTemplate, Loading, Error },

  setup() {
    const route = useRoute();
    const pathCode = route.params.joinCode;
    const queryCode = route.query.code?.toString();
    const postToParent =
      route.query.post_to_parent?.toString() === "true" ? true : false;
    const redirectURI = route.query.redirect_uri?.toString() || "";

    const code = pathCode ? pathCode : queryCode;

    const template = ref<UIContentJSON>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const interaction = ref<any>(null);
    const error = ref<string>("");
    const identityUI = ref(false);

    if (!code) {
      error.value = "No Code Provided";
      return { error };
    }

    const buttonCmd = (
      buttonRole: ButtonAction,
      inputs?: InputAnswer[],
      actionNext?: ActionNext
    ) => {
      if (buttonRole === "CONTINUE") {
        interaction.value.proceed(inputs, actionNext);
      } else if (buttonRole === "CANCEL" || buttonRole === "CANCEL_AND") {
        if (postToParent) {
          const result = {
            status: "REJECTED",
          };
          window.parent.postMessage(result, redirectURI);
        }
        interaction.value.cancel(actionNext);
      }
    };

    const config: ICustomerAuthnConfig = {
      connectionURL: process.env.VUE_APP_SOCKET_CONNECTION_URL,
      channelID: "EHS",
      timeoutInMilliseconds: 10000,
      redirectDelayInMilliseconds: 2000,
    };

    SDK.init(config)
      .then((sdk) => {
        sdk
          .onSetAuthenticationFactor()
          .subscribe((result: ISetAuthenticationFactor) => {
            interaction.value = result;
            if (result.uiContent && "contentJSON" in result.uiContent) {
              template.value = (result.uiContent as UIPage).contentJSON;
            } else {
              template.value = result.uiContent as UIContentJSON;
            }
          });

        sdk.onChallengeCustomer().subscribe((result: IChallenge) => {
          interaction.value = result;
          if (result.uiContent && "contentJSON" in result.uiContent) {
            template.value = (result.uiContent as UIPage).contentJSON;
          } else {
            template.value = result.uiContent as UIContentJSON;
          }
        });

        sdk.onNotify().subscribe((result: INotify) => {
          interaction.value = result;
          if (result.uiContent && "contentJSON" in result.uiContent) {
            template.value = (result.uiContent as UIPage).contentJSON;
          } else {
            template.value = result.uiContent as UIContentJSON;
          }
        });

        sdk
          .onIdentifyCustomer()
          .subscribe(async (identifyCustomer: IIdentifyCustomer) => {
            identityUI.value = true;
            await identifyCustomer.proceed();
            identityUI.value = false;
          });

        sdk
          .joinInteraction({
            entersektJoinCode: code as string,
            postToParent,
            originRedirectURI: redirectURI,
          })
          .then(() => {
            console.log("joined");
          })
          .catch((err) => {
            error.value = `Something went wrong: ${err.toString()}`;
          });
      })
      .catch((err) => {
        error.value = `Something went wrong: ${err.toString()}`;
      });

    return { template, error, buttonCmd, identityUI };
  },
  watch: {
    template() {
      if (this.template?.theme?.pageBackgroundColor) {
        this.$emit(
          "setBackgroundColor",
          this.template?.theme?.pageBackgroundColor
        );
      }
    },
  },
  emit: ["setBackgroundColor"],
});
