import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", {
      style: _normalizeStyle({ color: _ctx.setLabelColor })
    }, _toDisplayString(_ctx.displayField.label), 5),
    _createTextVNode(": "),
    _createElementVNode("p", {
      style: _normalizeStyle({ color: _ctx.setValueColor })
    }, _toDisplayString(_ctx.displayField.value), 5)
  ], 64))
}