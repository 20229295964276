import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Entersekt FIDO",
    },
  },
  {
    path: "/:channelID/:joinCode",
    name: "Join",
    component: Home,
    meta: {
      title: "Entersekt FIDO",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: NotFound,
    meta: {
      title: "Page not found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
