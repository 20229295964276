import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_SelectInput = _resolveComponent("SelectInput")!
  const _component_OtpInput = _resolveComponent("OtpInput")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputs, (input) => {
      return (_openBlock(), _createElementBlock("div", {
        key: input.inputID
      }, [
        (input.type === 'NUMBER')
          ? (_openBlock(), _createBlock(_component_NumberInput, {
              key: 0,
              onOnUpdate: _ctx.onInputChange,
              inputID: input.inputID,
              label: input.label,
              max: input.max,
              min: input.min,
              required: input.required,
              "is-Disabled": _ctx.isDisabled
            }, null, 8, ["onOnUpdate", "inputID", "label", "max", "min", "required", "is-Disabled"]))
          : _createCommentVNode("", true),
        (input.type === 'TEXT')
          ? (_openBlock(), _createBlock(_component_TextInput, {
              key: 1,
              onOnUpdate: _ctx.onInputChange,
              inputID: input.inputID,
              label: input.label,
              maxLength: input.maxLength,
              minLength: input.minLength,
              required: input.required,
              "is-Disabled": _ctx.isDisabled
            }, null, 8, ["onOnUpdate", "inputID", "label", "maxLength", "minLength", "required", "is-Disabled"]))
          : _createCommentVNode("", true),
        (input.type === 'SELECT')
          ? (_openBlock(), _createBlock(_component_SelectInput, {
              key: 2,
              onOnUpdate: _ctx.onInputChange,
              inputID: input.inputID,
              label: input.label,
              values: input.values,
              "is-Disabled": _ctx.isDisabled
            }, null, 8, ["onOnUpdate", "inputID", "label", "values", "is-Disabled"]))
          : _createCommentVNode("", true),
        (input.type === 'OTP')
          ? (_openBlock(), _createBlock(_component_OtpInput, {
              key: 3,
              onOnUpdate: _ctx.onInputChange,
              inputID: input.inputID,
              label: input.label,
              required: input.required,
              length: _ctx.inputLength(input.maxLength, input.minLength),
              "is-Disabled": _ctx.isDisabled
            }, null, 8, ["onOnUpdate", "inputID", "label", "required", "length", "is-Disabled"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}