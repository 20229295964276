
import { defineComponent, PropType } from "vue";
import FootLogoComponent from "./FooterLogo.vue";

export default defineComponent({
  name: "LogosComponent",
  components: { FootLogoComponent },

  props: {
    logos: {
      required: true,
      type: Object as PropType<string[]>,
    },
  },
});
