import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayTemplate = _resolveComponent("DisplayTemplate")!
  const _component_Error = _resolveComponent("Error")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.template && !_ctx.identityUI)
      ? (_openBlock(), _createBlock(_component_DisplayTemplate, {
          key: 0,
          template: _ctx.template,
          onButtonClicked: _ctx.buttonCmd
        }, null, 8, ["template", "onButtonClicked"]))
      : (_ctx.error && !_ctx.identityUI)
        ? (_openBlock(), _createBlock(_component_Error, {
            key: 1,
            error: _ctx.error
          }, null, 8, ["error"]))
        : (!_ctx.identityUI && !_ctx.error && !_ctx.template)
          ? (_openBlock(), _createBlock(_component_Loading, {
              key: 2,
              "large-spinner": true
            }))
          : _createCommentVNode("", true)
  ]))
}